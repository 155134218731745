*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing:antialiased;
} 




body{
  background-color: #F7F7F7;
  overflow-x: hidden;
}

@media(max-width: 960px ){
      body{
          overflow-x: hidden;
      }
}

 li {
  list-style: none;
}

a {
  text-decoration: none;
}

.container{
    padding: 0 15px;
    margin: 0 auto;
    max-width: 1152px;
    overflow: hidden;
}

::-webkit-scrollbar {
  background-color: white;
  width: 0.4vw;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(to bottom, #fc4958, #e85d04, #fc4958);
}

@media(min-width:576px){
    .container{
        max-width: 540px;
    }
}

@media(min-width:768px){
    .container{
        max-width: 720px;
    }
}

@media(min-width:992px){
    .container{
        max-width: 960px;
    }
}

@media(min-width:1200px){
    .container{
        max-width: 1152px;
    }
}